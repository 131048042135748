import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMonth: "",
  selectedYear: "",
  status: "fetching",
  selectedCategory: "all",
  selectedType: "all",
  selectedLocation: [],
  selectedProvider: "all",
  filteredCourseDates: [],
};

const CourseCalendarSlice = createSlice({
  name: "calendar course",
  initialState,
  reducers: {
    selectMonth(state, action) {
      state.selectedMonth = action.payload;
    },
    selectYear(state, action) {
      state.selectedYear = action.payload;
    },
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    selectType(state, action) {
      state.selectedType = action.payload;
    },
    selectLocation(state, action) {
      state.selectedLocation = action.payload;
    },
    deselectLocation(state, action) {
      state.selectedLocation = state.selectedLocation.filter(
        (location) => location !== action.payload
      );
    },
    resetLocations(state) {
      state.selectedLocation = [];
    },
    selectProvider(state, action) {
      state.selectedProvider = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setCourseDates(state, action) {
      state.filteredCourseDates = action.payload;
    },
  },
});

export const {
  selectMonth,
  selectYear,
  selectCategory,
  selectType,
  selectLocation,
  deselectLocation,
  resetLocations,
  selectProvider,
  setStatus,
  setCourseDates,
} = CourseCalendarSlice.actions;

export default CourseCalendarSlice.reducer;
