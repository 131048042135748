import icon from "../../images/search-solid.png";
import active from "../../images/desktop-active.png";

export default (theme) => ({
  menuClose: {
    position: "absolute",
    display: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
    border: "none",
    zIndex: 9,
    width: "100%",
    padding: 0,
    '&[data-active="true"]': {
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    "&.over-nav": {
      zIndex: 1000,
      position: "fixed",
      bottom: "auto",
      height: 260,
    },
  },
  topNav: {
    backgroundColor: theme.palette.primary.main,
    borderTop: "4px solid " + theme.palette.secondary.main,
    position: "relative",
    zIndex: 10,
    marginBottom: 5,
    "& .container": {
      width: "100%",
      maxWidth: 1264,
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        position: "relative",
      },
      "& .site-logo": {
        padding: 24,
        borderBottom: "1px solid " + theme.palette.primary.contrastText,
        "& .gatsby-image-wrapper": {
          maxWidth: "210px !important",
          margin: "0 auto",
          [theme.breakpoints.up("md")]: {
            maxWidth: "100% !important",
          },
        },
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
          padding: "55px 12px",
          borderBottom: "none",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 25%",
          maxWidth: "25%",
        },
      },
      "& .search": {
        position: "absolute",
        bottom: -30,
        left: 12,
        right: 12,
        [theme.breakpoints.up("md")]: {
          position: "relative",
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
          padding: "0 12px",
          bottom: "auto",
          left: "auto",
          right: "auto",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
          "&::after": {
            content: '""',
            borderRight: "2px solid " + theme.palette.secondary.main,
            borderBottom: "2px solid " + theme.palette.secondary.main,
            display: "inline-block",
            width: 10,
            height: 10,
            position: "absolute",
            right: 90,
            top: "calc(50% - 4px)",
            transform: "translateY(-50%) rotate(45deg)",
            transition: "bottom .3s ease-in-out",
          },
        },
        '& input[type="text"]': {
          display: "block",
          fontFamily: "Overpass",
          fontSize: 16,
          lineHeight: 1.5,
          color: theme.palette.primary.main,
          padding: "18px 72px 14px 30px",
          borderRadius: 30,
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          border: "none",
          margin: 0,
          width: "100%",
          outline: "none",
          [theme.breakpoints.up("lg")]: {
            paddingRight: 290,
          },
        },
        "& select": {
          display: "none",
          [theme.breakpoints.up("lg")]: {
            position: "absolute",
            display: "block",
            zIndex: 2,
            width: 230,
            top: 0,
            right: 70,
            color: theme.palette.secondary.main,
            fontFamily: theme.headerFont,
            fontSize: 14,
            letterSpacing: "0.016em",
            lineHeight: 1.72,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: "16px 10px",
            appearance: "none",
            "-webkit-appearance": "none",
            "-moz-appearance": "none",
            "-ms-appearance": "none",
          },
          "& option": {
            fontFamily: theme.headerFont + ", sans-serif",
          },
        },
        '& input[type="submit"]': {
          position: "absolute",
          outline: "none",
          top: 4,
          right: 4,
          width: 48,
          height: 48,
          border: "none",
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "50%",
          fontSize: 0,
          backgroundPosition: "center",
          backgroundImage: "url(" + icon + ")",
          backgroundRepeat: "no-repeat",
          [theme.breakpoints.up("md")]: {
            right: 16,
          },
        },
      },
      "& .quick-links": {
        padding: "28px 12px 50px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
          padding: "0 12px",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 25%",
          maxWidth: "25%",
        },
        "& button, & a": {
          padding: 0,
          backgroundColor: "transparent",
          border: "none",
          flex: "0 0 25%",
          maxWidth: "25%",
          textAlign: "center",
          fontFamily: theme.headerFont,
          color: theme.palette.primary.contrastText,
          fontSize: 18,
          lineHeight: 1.5,
          textDecoration: "none",
          outline: "none",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 33.333%",
            maxWidth: "33.333%",
          },
          "& img": {
            display: "block",
            margin: "0 auto 6px",
          },
        },
        "& button": {
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
          "& span": {
            width: 25,
            height: 4,
            borderRadius: 2,
            backgroundColor: theme.palette.primary.contrastText,
            margin: "17px auto 23px",
            display: "block",
            boxShadow: "0 8px 0 white, 0 -8px 0 white",
          },
        },
        "& .wishlist": {
          position: "relative",
          "& span": {
            fontSize: 8,
            width: 12,
            height: 12,
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
            display: "block",
            position: "absolute",
            lineHeight: "12px",
            top: 8,
            left: "calc(50% + 2px)",
            padding: "1px 1px 0 0",
          },
        },
      },
      "& .account-link": {
        display: "none",
        position: "absolute",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 16,
        fontFamily: "Roboto Condensed",
        lineHeight: "20px",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        top: 0,
        right: 12,
        textDecoration: "none",
        padding: "5px 20px",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
        "& svg": {
          width: 18,
          height: 18,
          verticalAlign: "top",
          marginRight: 5,
        },
      },
    },
    "& .desktop-nav": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      backgroundColor: "white",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      "&::after": {
        height: 8,
        backgroundColor: theme.palette.secondary.main,
        left: 0,
        right: 0,
        bottom: -8,
        position: "absolute",
        zIndex: -2,
        content: '""',
        display: "block",
      },
      "& .deep-nav": {
        flex: "1 1 0px",
        justifyContent: "center",
        "&[open]": {
          "& .deep-nav-links": {
            opacity: 1,
            left: "50%",
            transform: "translateX(-50%)",
          },
          "& button": {
            color: theme.palette.secondary.main,
            "&::before": {
              bottom: -44,
            },
            "&::after": {
              bottom: 0,
            },
          },
        },
        "& button": {
          display: "block",
          width: "100%",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          fontSize: 18,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          color: theme.palette.primary.main,
          fontFamily: theme.headerFont,
          padding: "30px 15px",
          position: "relative",
          transition: "color .3s ease-in-out",
          "& .gatsby-image-wrapper, & > img": {
            width: 36,
            maxWidth: "36px !important",
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: 6,
          },
          "&::before": {
            content: '""',
            width: 91,
            height: 82,
            position: "absolute",
            backgroundImage: "url(" + active + ")",
            left: "calc(50% - 45.5px)",
            zIndex: -1,
            bottom: 0,
            transition: "bottom .3s ease-in-out",
          },
          "&::after": {
            content: '""',
            borderRight: "3px solid " + theme.palette.secondary.main,
            borderBottom: "3px solid " + theme.palette.secondary.main,
            display: "inline-block",
            width: 12,
            height: 12,
            position: "absolute",
            left: "50%",
            bottom: 15,
            transform: "translateX(-50%) rotate(45deg)",
            transition: "bottom .3s ease-in-out",
          },
        },
        "& .deep-nav-links": {
          position: "absolute",
          maxWidth: 1085,
          width: "calc(100% - 12px)",
          top: "calc(100% + 18px)",
          left: "150%",
          backgroundColor: theme.palette.background.primary,
          border: "20px solid white",
          boxShadow: "0 8px 26px rgba(27,54,93,0.73)",
          padding: 50,
          opacity: 0,
          overflow: "hidden",
          zIndex: -2,
          transition: "opacity .3s ease-in-out",
          "& a.all": {
            float: "right",
            width: 210,
            border: "20px solid white",
            position: "relative",
            "& img": {
              minHeight: 180,
              objectFit: "cover",
            },
            "& span": {
              backgroundColor: "white",
              fontFamily: theme.headerFont,
              fontSize: 18,
              letterSpacing: "0.016em",
              position: "absolute",
              bottom: -1,
              right: 32,
              left: -5,
              color: theme.palette.primary.main,
              padding: "5px 12px 5px 5px",
              clipPath:
                "polygon(0% 0%, calc(100% - 22px) 0, 100% 32px, 100% 100%, 0 100%)",
            },
            "&::after": {
              content: '""',
              borderRight: "3px solid white",
              borderBottom: "3px solid white",
              display: "inline-block",
              width: 12,
              height: 12,
              position: "absolute",
              right: 15,
              bottom: 12,
              transform: "rotate(-45deg)",
            },
          },
          "& .nav-columns": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "none",
            width: "calc(100% - 210px)",
            "& > a": {
              padding: "20px 20px 22px 80px",
              borderBottom: "1px solid rgba(0,0,0,0.3)",
              fontFamily: theme.bodyFont,
              fontSize: 16,
              color: theme.palette.primary.main,
              lineHeight: 1.25,
              display: "block",
              position: "relative",
              textDecoration: "none",
              flex: "0 0 calc(50% - 24px)",
              maxWidth: "calc(50% - 24px)",
              marginRight: 24,
              [theme.breakpoints.up("lg")]: {
                flex: "0 0 calc(33.333% - 24px)",
                maxWidth: "calc(33.3330% - 24px)",
                marginRight: 24,
              },
              "& .gatsby-image-wrapper, & > img": {
                width: 60,
                maxWidth: "60px !important",
                position: "absolute !important",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
              },
            },
          },
        },
      },
    },
  },
  mobileNav: {
    position: "absolute",
    top: 0,
    left: "-100vw",
    width: "calc(100% - 50px)",
    backgroundColor: "white",
    borderTop: "8px solid " + theme.palette.secondary.main,
    boxShadow: "0 0 0 100000px rgba(0,0,0,0.7)",
    zIndex: 999,
    opacity: 0,
    minHeight: "calc(100vh - 50px)",
    transition: "left .3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    "&[open]": {
      opacity: 1,
      left: 0,
    },
    "& button.menu-trigger": {
      fontSize: 0,
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      position: "absolute",
      top: 24,
      left: "calc(100% + 18px)",
    },
    "& > a": {
      padding: "24px 36px 22px 12px",
      display: "block",
      fontFamily: "Overpass",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontSize: 16,
      lineHeight: 1.25,
      letterSpacing: "-0.008em",
      position: "relative",
      "& img": {
        width: 20,
        display: "inline-block",
        marginRight: 12,
        marginBottom: -4,
      },
      "&::after": {
        content: '""',
        borderRight: "3px solid " + theme.palette.secondary.main,
        borderBottom: "3px solid " + theme.palette.secondary.main,
        display: "inline-block",
        width: 12,
        height: 12,
        position: "absolute",
        right: 18,
        top: "50%",
        transform: "translateY(-50%) rotate(-45deg)",
      },
    },
    "& .deep-nav": {
      position: "relative",
      "&[open]": {
        "& .deep-nav-links": {
          height: "auto",
          opacity: 1,
        },
        "& > button": {
          "&::before": {
            opacity: 1,
          },
        },
      },
      "& > button": {
        padding: "24px 36px 22px 44px",
        display: "block",
        fontFamily: "Overpass",
        fontWeight: "bold",
        color: theme.palette.primary.main,
        textDecoration: "none",
        fontSize: 16,
        lineHeight: 1.25,
        letterSpacing: "-0.008em",
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        width: "100%",
        textAlign: "left",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
        "&::after": {
          content: '""',
          borderRight: "3px solid " + theme.palette.secondary.main,
          borderBottom: "3px solid " + theme.palette.secondary.main,
          display: "inline-block",
          width: 12,
          height: 12,
          position: "absolute",
          right: 18,
          top: "50%",
          transform: "translateY(-50%) rotate(-45deg)",
        },
        "&::before": {
          backgroundColor: "#EDEDED",
          display: "block",
          content: '""',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: -1,
          position: "absolute",
          clipPath:
            "polygon(0% 0%, 100% 0%, calc(100% - 36px) 12px, calc(100% - 36px) calc(100% - 12px), 100% 100%, 0 100%)",
          opacity: 0,
          transition: "opacity .3s ease-in-out",
        },
        "& .gatsby-image-wrapper, & > img": {
          maxWidth: "20px !important",
          marginRight: 12,
          marginBottom: -4,
          width: 20,
          position: "absolute !important",
          left: 12,
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
      "& .deep-nav-links": {
        position: "absolute",
        top: "calc(100% + 10px)",
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 2,
        height: 0,
        opacity: 0,
        overflow: "hidden",
        transition: "opacity .3s ease-in-out",
        "& > a": {
          padding: "12px 36px 12px 44px",
          fontFamily: "Overpass",
          fontSize: 16,
          letterSpacing: "0.016em",
          textDecoration: "none",
          color: theme.palette.primary.main,
          display: "block",
          borderBottom: "1px solid #EDEDED",
          position: "relative",
          "&.all": {
            fontWeight: "bold",
          },
          "&::after": {
            content: '""',
            borderRight: "1px solid " + theme.palette.primary.main,
            borderBottom: "1px solid " + theme.palette.primary.main,
            borderRadius: 1,
            display: "inline-block",
            width: 10,
            height: 10,
            position: "absolute",
            right: 18,
            top: "50%",
            transform: "translateY(-50%) rotate(-45deg)",
          },
        },
      },
    },
  },
  topNavTheme: {},
});
