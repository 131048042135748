import {
  SET_SORT,
  SET_ITEMS_TO_SHOW,
  SET_FILTER,
  WISHLIST_ADD_ITEM,
  WISHLIST_REMOVE_ITEM,
  SET_PAGE,
  COURSE_SET_NAME,
  COURSE_SET_CATEGORY,
  COURSE_SET_TYPE,
  COURSE_SET_PLACES,
  COURSE_SET_PROVIDER,
  COOKIE_CONSENT_ALL,
  COOKIE_CONSENT_SET,
  SET_QUERY
} from "./types";

export function setSort(sort) {
  return {
    type: SET_SORT,
    sort,
  };
}

export function setItemsToShow(itemsToShow) {
  return {
    type: SET_ITEMS_TO_SHOW,
    itemsToShow,
  };
}

export function setPage(page) {
  return {
    type: SET_PAGE,
    page,
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    filter,
  };
}

export function addToWishlist(item) {
  return {
    type: WISHLIST_ADD_ITEM,
    item,
  };
}

export function removeFromWishlist(item) {
  return {
    type: WISHLIST_REMOVE_ITEM,
    item,
  };
}

export function setCourseName(name) {
  return {
    type: COURSE_SET_NAME,
    name,
  };
}
export function setCourseCategory(category) {
  return {
    type: COURSE_SET_CATEGORY,
    category,
  };
}
export function setCourseType(type) {
  return {
    type: COURSE_SET_TYPE,
    courseType: type,
  };
}
export function setSearchPlaces(searchPlaces) {
  return {
    type: COURSE_SET_PLACES,
    searchPlaces
  }
}
export function setCourseProvider(provider) {
  return {
    type: COURSE_SET_PROVIDER,
    provider,
  };
}

export function setCookieConsentAll() {
  return {
    type: COOKIE_CONSENT_ALL,
  };
}
export function setCookieConsent(level, value) {
  return {
    type: COOKIE_CONSENT_SET,
    level: level,
    value: value,
  };
}
export function setQuery(query) {
  return {
    type: SET_QUERY,
    query
  }
}
