export const SET_SORT = "SET_SORT";
export const SET_ITEMS_TO_SHOW = "SET_ITEMS_TO_SHOW";
export const SET_FILTER = "SET_FILTER";
export const WISHLIST_ADD_ITEM = "WISHLIST_ADD_ITEM";
export const WISHLIST_REMOVE_ITEM = "WISHLIST_REMOVE_ITEM";
export const SET_PAGE = "SET_PAGE";
export const COURSE_SET_NAME = "COURSE_SET_NAME";
export const COURSE_SET_CATEGORY = "COURSE_SET_CATEGORY";
export const COURSE_SET_TYPE = "COURSE_SET_TYPE";
export const COURSE_SET_PLACES = "COURSE_SET_PLACES";
export const COURSE_SET_PROVIDER = "COURSE_SET_PROVIDER";
export const COOKIE_CONSENT_ALL = "COOKIE_CONSENT_ALL";
export const COOKIE_CONSENT_SET = "COOKIE_CONSENT_SET";
export const SET_QUERY = "SET_QUERY";
