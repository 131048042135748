import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Link from "../Link";
import { Link as GatsbyLink } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import { connect } from "react-redux";

import contactWhite from "../../images/nav-icon-white-contact.png";
import distributorsWhite from "../../images/nav-icon-white-distributors.png";
import wishlistWhite from "../../images/nav-icon-white-wishlist.png";

import contactBlue from "../../images/nav-icon-blue-contact.png";
import distributorsBlue from "../../images/nav-icon-blue-distributors.png";
import wishlistBlue from "../../images/nav-icon-blue-wishlist.png";

import close from "../../images/icon-close.png";

import PersonIcon from "@material-ui/icons/Person";

import {
  setCourseName,
  setCourseCategory,
  setCourseType,
  setCourseProvider,
  setQuery,
} from "../../app/actions";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
  query: state.SearchSettings.query,
});

const mapDispatchToProps = (dispatch) => ({
  setCourseName: (name) => dispatch(setCourseName(name)),
  setCourseCategory: (category) => dispatch(setCourseCategory(category)),
  setCourseType: (type) => dispatch(setCourseType(type)),
  setCourseProvider: (provider) => dispatch(setCourseProvider(provider)),
  setQuery: (query) => dispatch(setQuery(query)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const useStyles = makeStyles((theme) => styles(theme));

function TopNav(props) {
  const [open, setOpen] = React.useState(false);
  const [nav1Open, setNav1Open] = React.useState(false); //eslint-disable-line
  const [nav2Open, setNav2Open] = React.useState(false); //eslint-disable-line
  const [nav3Open, setNav3Open] = React.useState(false); //eslint-disable-line
  const [openSub, setOpenSub] = React.useState("");

  const [category, setCategory] = React.useState("all");

  const { setCourseCategory, setCourseType, setCourseProvider, setQuery, query } = props;

  const handleFullMenuOpen = () => {
    setOpen(!open);
    setNav1Open(false);
    setNav2Open(false);
    setNav3Open(false);
  };

  // const handleNav1Open = () => {
  //   setNav1Open(!nav1Open);
  //   setNav2Open(false);
  //   setNav3Open(false);
  // };

  // const handleNav2Open = () => {
  //   setNav2Open(!nav2Open);
  //   setNav1Open(false);
  //   setNav3Open(false);
  // };

  // const handleNav3Open = () => {
  //   setNav3Open(!nav3Open);
  //   setNav2Open(false);
  //   setNav1Open(false);
  // };

  function onSubmitHandler(e) {
    e.preventDefault();

    if (category === "training") {
      setCourseCategory("all");
      setCourseType("all");
      setCourseProvider("all");

      navigate(`/training?${query ? `search=${query}` : ""}`, {
        state: { query },
      });
    } else {
      navigate("/search", { state: { query, category } });
    }
  }

  function handleUserKeyPress(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      onSubmitHandler(e);
    }
  }

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query TopNavItems {
      wpMenu(locations: { in: HEADER_MENU }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
            cssClasses
            MenuItemImage {
              image {
                localFile {
                  publicURL
                }
              }
              overviewImage {
                localFile {
                  publicURL
                }
              }
            }
            childItems {
              nodes {
                id
                label
                path
                cssClasses
                MenuItemImage {
                  image {
                    localFile {
                      publicURL
                    }
                  }
                }
                childItems {
                  nodes {
                    id
                    label
                    path
                    cssClasses
                  }
                }
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            siteLogo {
              localFile {
                publicURL
              }
            }
            cvLogo {
              localFile {
                publicURL
              }
            }
            lvLogo {
              localFile {
                publicURL
              }
            }
            trainingLogo {
              localFile {
                publicURL
              }
            }
            academyLogo {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  var i = data.wpMenu.menuItems.nodes.length;

  while (i--) {
    if (data.wpMenu.menuItems.nodes[i].parentId != null) {
      data.wpMenu.menuItems.nodes.splice(i, 1);
    }
  }

  function filterMainCategorySlug(name) {
    if (name.includes("LV")) {
      return name.replace("LV", "").trim();
    }

    if (name.includes("CV")) {
      return name.replace("CV", "").trim();
    }

    return name;
  }

  // console.log(props);

  let siteLogo = data.wp.themeOptions.ThemeOptions.siteLogo.localFile.publicURL;

  if (props.path !== null) {
    if (props.path.indexOf("/car-workshop-equipment/") !== -1) {
      siteLogo =
        data.wp.themeOptions?.ThemeOptions?.lvLogo?.localFile?.publicURL ||
        siteLogo;
    } else if (props.path.indexOf("/commercial-workshop-equipment/") !== -1) {
      siteLogo =
        data.wp.themeOptions?.ThemeOptions?.cvLogo?.localFile?.publicURL ||
        siteLogo;
    } else if (props.path.indexOf("/training/") !== -1) {
      siteLogo =
        data.wp.themeOptions.ThemeOptions?.trainingLogo?.localFile?.publicURL ||
        siteLogo;
    } else if (props.path.indexOf("/course/") !== -1) {
      siteLogo =
        data.wp.themeOptions.ThemeOptions?.trainingLogo?.localFile?.publicURL ||
        siteLogo;
    } else if (props.path.indexOf("/workshop-academy/") !== -1) {
      siteLogo =
        data.wp.themeOptions.ThemeOptions?.academyLogo?.localFile?.publicURL ||
        siteLogo;
    }
  }

  return (
    <React.Fragment>
      <div className={`top-nav  ${classes.topNav}  ${classes.topNavTheme}`}>
        <div className="container">
          <div className="site-logo">
            <Link to="/">
              <img src={siteLogo} alt="" />
            </Link>
          </div>
          <div className="search">
            <form onSubmit={onSubmitHandler}>
              <input
                type="text"
                name="search"
                placeholder="What are you looking for?"
                minLength={2}
                required
                onKeyDown={handleUserKeyPress}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <select name="filter" onBlur={(e) => setCategory(e.target.value)}>
                <option value="all">In All Departments</option>
                <option value="car-workshop-equipment">
                  Car Workshop Equipment
                </option>
                <option value="commercial-workshop-equipment">
                  Commercial Workshop Equipment
                </option>
                <option value="training">Training</option>
              </select>
              <input type="submit" value="search" />
            </form>
          </div>
          <div className="quick-links">
            <button
              className="menu-trigger"
              onClick={handleFullMenuOpen}
              onKeyDown={handleFullMenuOpen}
            >
              <span />
              Menu
            </button>
            <Link to="/distributors/">
              <img src={distributorsWhite} alt="" />
              Distributors
            </Link>
            <Link to="/contact-us/">
              <img src={contactWhite} alt="" />
              Contact Us
            </Link>
            <Link to="/wish-list/" className="wishlist">
              <img src={wishlistWhite} alt="" />
              Wish List{" "}
              {props.wishlist.length ? (
                <span>{props.wishlist.length}</span>
              ) : null}
            </Link>
          </div>
          <Link to="/account/" className="account-link">
            <PersonIcon /> My Account
          </Link>
        </div>
        <div className="desktop-nav">
          <div className="container">
            {data.wpMenu.menuItems.nodes.map((menu) => {
              return (
                <div
                  key={menu.id}
                  className="deep-nav"
                  open={openSub === menu.id ? true : false}
                >
                  <button
                    className="open-deep-nav"
                    onClick={() => setOpenSub(menu.id)}
                    onKeyDown={() => setOpenSub(menu.id)}
                  >
                    <img
                      src={menu.MenuItemImage.image.localFile.publicURL}
                      alt=""
                    />
                    {ReactHtmlParser(menu.label)}
                  </button>
                  <div className="deep-nav-links">
                    <Link to={menu.path} className="all">
                      <img
                        src={
                          menu.MenuItemImage.overviewImage.localFile.publicURL
                        }
                        alt=""
                      />
                      <span>
                        {ReactHtmlParser(filterMainCategorySlug(menu.label))}
                      </span>
                    </Link>
                    <div className="nav-columns">
                      {menu.childItems.nodes.map((subSection) => {
                        return (
                          <GatsbyLink key={subSection.id} to={subSection.path}>
                            <img
                              src={
                                subSection.MenuItemImage.image.localFile
                                  .publicURL
                              }
                              alt=""
                            />
                            {ReactHtmlParser(
                              filterMainCategorySlug(subSection.label)
                            )}
                          </GatsbyLink>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <button
        className={classes.menuClose}
        aria-label="close"
        data-active={openSub !== "" ? true : false}
        onClick={() => {
          setOpenSub("");
        }}
        onKeyDown={() => {
          setOpenSub("");
        }}
      ></button>

      <button
        className={classes.menuClose + " over-nav"}
        aria-label="Close"
        data-active={openSub !== "" ? true : false}
        onClick={() => {
          setOpenSub("");
        }}
        onKeyDown={() => {
          setOpenSub("");
        }}
      ></button>

      <div className={`mobile-site-nav  ${classes.mobileNav}`} open={open}>
        <button
          className="menu-trigger"
          onClick={handleFullMenuOpen}
          onKeyDown={handleFullMenuOpen}
        >
          <img src={close} alt="" />
          Close
        </button>
        <Link to="/account/">Account</Link>
        {data.wpMenu.menuItems.nodes.map((menu) => {
          return (
            <div
              key={menu.id}
              className="deep-nav"
              open={openSub === menu.id ? true : false}
            >
              <button
                className="open-deep-nav"
                onClick={() => setOpenSub(menu.id)}
                onKeyDown={() => setOpenSub(menu.id)}
              >
                <img
                  src={menu.MenuItemImage.image.localFile.publicURL}
                  alt=""
                />
                {ReactHtmlParser(menu.label)}
              </button>
              <div className="deep-nav-links">
                <Link to={menu.path} className="all">
                  All {ReactHtmlParser(menu.label)}
                </Link>
                {menu.childItems.nodes.map((subSection) => (
                  <Link key={subSection.id} to={subSection.path}>
                    {ReactHtmlParser(filterMainCategorySlug(subSection.label))}
                  </Link>
                ))}
              </div>
            </div>
          );
        })}
        <Link to="/distributors/">
          <img src={distributorsBlue} alt="" />
          Distributors
        </Link>
        <Link to="/contact-us/">
          <img src={contactBlue} alt="" />
          Contact Us
        </Link>
        <Link to="/wish-list/">
          <img src={wishlistBlue} alt="" />
          Wish List{" "}
          {props.wishlist.length ? <span>{props.wishlist.length}</span> : null}
        </Link>
      </div>
    </React.Fragment>
  );
}

export default connector(TopNav);
