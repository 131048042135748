import axios from "axios";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {};

export const setUser = (user) => {
  window.localStorage.setItem("user", JSON.stringify(user));
};

export const handleLogin = ({
  id,
  login,
  name,
  firstName,
  lastName,
  group,
  token,
  refreshToken,
}) => {
  return setUser({
    id,
    login,
    name,
    firstName,
    lastName,
    group,
    token,
    refreshToken,
  });
};

export const loginIsEnabled = () => true

export const isLoggedIn = () => {
  const user = getUser();
  if (!loginIsEnabled()) {
    // Login is disabled, logout anyone who is logged in
    if (user) {
      logout(() => false)
    }
  }
  return authenticateUser(user.login);
};

export const mutationLogin = ({ login, password }) => {
  return {
    query: `
    mutation LoginUser {
      login( input: {
        username: "${login}",
        password: "${password}"
      } ) {
        authToken
        refreshToken
        user {
          id
          name
          firstName
          lastName
          userGroup {
            group {
              ... on Group {
                slug
              }
            }
          }
        }
      }
    }
  `,
  };
};

export const mutationRefresh = (token) => {
  return {
    query: `
    mutation {
      refreshJwtAuthToken(input: {jwtRefreshToken: "${token}"}) {
        authToken
      }
    }
    `,
  };
};

export const mutationEdit = ({
  id,
  firstname = "",
  lastname = "",
  password,
  email,
}) => {
  return {
    query: `
    mutation EditUser {
      updateUser(input: {id: "${id}", firstName: "${firstname}", lastName: "${lastname}", email: "${email}", password: "${password}"}) {
        user {
          id
          email
          firstName
          username
          lastName
        }
      }
    }
  `,
  };
};

export const mutationResetPassword = ({ email }) => {
  return {
    query: `
    mutation ResetPassword {
      sendPasswordResetEmail(input: {username: "${email}"}) {
        user {
          name
        }
      }
    }
  `,
  };
};

export const authenticateUser = (login) => {
  return axios.post(
    `${process.env.GATSBY_ADMIN_URL}graphql`,
    {
      query: `
    {
        user(id: "${login}", idType: EMAIL) {
            username
            userGroup {
                fieldGroupName
                group {
                  ... on Group {
                    id
                    slug
                    title
                  }
                }
            }
        }
    }
  `,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUser().token}`,
      },
    }
  );
};

export const logout = (callback) => {
  setUser({});
  callback();
};
