import Cookies from "js-cookie";
import { isBrowser } from "../helpers";
// import { gtag } from "../../gatsby-browser";
import {
  SET_SORT,
  SET_ITEMS_TO_SHOW,
  SET_FILTER,
  WISHLIST_ADD_ITEM,
  WISHLIST_REMOVE_ITEM,
  SET_PAGE,
  COURSE_SET_NAME,
  COURSE_SET_CATEGORY,
  COURSE_SET_TYPE,
  COURSE_SET_PROVIDER,
  COOKIE_CONSENT_ALL,
  COOKIE_CONSENT_SET,
  COURSE_SET_PLACES,
  SET_QUERY,
} from "./types";

const initialShopState = {
  sort: "recommended",
  itemsToShow: 9,
  page: 1,
  filters: {
    brand: "",
    min: 1,
    max: 99999999,
  },
};

function getInitialWishlistState() {
  if (!isBrowser) return [];

  const cookie = Cookies.get("wishlist");

  if (cookie) {
    return JSON.parse(Cookies.get("wishlist"));
  } else {
    return [];
  }
}

function getInitialCourseSearchState() {
  return {
    name: "",
    category: "all",
    type: "all",
    searchPlaces: [],
    provider: "all",
  };
}

// function getInitialCookieConsentState() {
//   if (!isBrowser) return {};

//   let newCookie = {
//     a: true,
//     b: true,
//     c: true,
//     d: true,
//     last_modified: null,
//   };

//   let cookie = Cookies.get("cookie_consent_settings");
//   if (cookie) {
//     let frags = cookie.split(",");
//     frags.forEach((value) => {
//       let pieces = value.split(":");
//       let consented = pieces[1] === "1";
//       switch (pieces[0]) {
//         case "last_modified":
//           newCookie[pieces[0]] = pieces[1];
//           break;
//         case "a":
//           newCookie["a"] = true;
//           break;
//         case "b":
//           newCookie["b"] = consented;
//           if (consented) {
//             if (gtag) {
//               gtag("consent", "update", { analytics_storage: "granted" });
//             }
//             if (window._paq !== "undefined") {
//               window._paq.push(["setCookieConsentGiven"]);
//             }
//           }
//           break;
//         case "c":
//           newCookie["c"] = consented;
//           break;
//         case "d":
//           newCookie["d"] = consented;
//           if (gtag && pieces[1] === "1") {
//             gtag("consent", "update", { ad_storage: "granted" });
//           }
//           break;
//         default:
//           break;
//       }
//     });
//   }
//   return newCookie;
// }

const initialWishlistState = getInitialWishlistState();
const initialCourseSearchState = getInitialCourseSearchState();
const initialSearchState = {
  query: "",
};
// const initialCookieConsentState = getInitialCookieConsentState();

export function ShopSettings(state = initialShopState, action) {
  switch (action.type) {
    case SET_SORT:
      return { ...state, sort: action.sort };
    case SET_ITEMS_TO_SHOW:
      return { ...state, itemsToShow: action.itemsToShow };
    case SET_FILTER:
      return { ...state, filters: action.filter };
    case SET_PAGE:
      return { ...state, page: action.page };
    default:
      return state;
  }
}

export function WishlistSettings(state = initialWishlistState, action) {
  switch (action.type) {
    case WISHLIST_ADD_ITEM:
      if (state.includes(action.item)) return state;
      return [...state, action.item];
    case WISHLIST_REMOVE_ITEM:
      return state.filter((item) => item !== action.item);
    default:
      return state;
  }
}

export function CourseSearchSettings(state = initialCourseSearchState, action) {
  switch (action.type) {
    case COURSE_SET_NAME:
      return { ...state, name: action.name };
    case COURSE_SET_CATEGORY:
      return { ...state, category: action.category };
    case COURSE_SET_TYPE:
      return { ...state, type: action.courseType };
    case COURSE_SET_PLACES:
      return { ...state, searchPlaces: action.searchPlaces };
    case COURSE_SET_PROVIDER:
      return { ...state, provider: action.provider };
    default:
      return state;
  }
}

export function SearchSettings(state = initialSearchState, action) {
  switch (action.type) {
    case SET_QUERY:
      return { ...state, query: action.query };
    default:
      return state;
  }
}

/* export function CookieConsentSettings(
  state = initialCookieConsentState,
  action
) {
  let date = new Date();
  switch (action.type) {
    case COOKIE_CONSENT_ALL:
      return {
        ...state,
        a: true,
        b: true,
        c: true,
        d: true,
        last_modified: date.getTime(),
      };
    case COOKIE_CONSENT_SET:
      let newState = { ...state, last_modified: date.getTime() };
      newState[action.level] = action.value;
      return newState;
    default:
      return state;
  }
} */
