import React from 'react';
import axios from 'axios';
import { getUser, setUser, mutationRefresh, isLoggedIn } from '../../services/auth';

export default class LoginSession extends React.Component {
  componentDidMount() {
    setInterval(async () => {
      const loggedIn = await isLoggedIn();
      if (!loggedIn.data.data.user) return;
      
      const prevToken = getUser().refreshToken;

      axios.post(`${process.env.GATSBY_ADMIN_URL}graphql`, mutationRefresh(prevToken), {
        headers: {
        'Content-Type': 'application/json',
        }
      }).then(res => {
        const newToken = res.data.data.refreshJwtAuthToken.authToken;
        setUser({
          ...getUser(),
          token: newToken,
        })
      })
    }, 240000)
  }

  render() {
    return null;
  }
}