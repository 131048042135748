import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  showComparison: false,
};

const CompareAppSlice = createSlice({
  name: "calendar course",
  initialState,
  reducers: {
    addProduct(state, action) {
      if (
        state.products.map((product) => product.id).includes(action.payload.id)
      )
        return state;

      if (state.products.length === 0) {
        state.products = [action.payload];
      } else {
        state.products = [state.products[0], action.payload];
      }
    },
    removeProduct(state, action) {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
    clearProducts(state, action) {
      state.products = [];
    },
    showComparison(state, action) {
      if (state.products.length === 2) {
        state.showComparison = true;
      }
    },
    hideComparison(state, action) {
      state.showComparison = false;
    },
  },
});

export const {
  addProduct,
  clearProducts,
  removeProduct,
  showComparison,
  hideComparison,
} = CompareAppSlice.actions;

export default CompareAppSlice.reducer;
