import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql, navigate } from "gatsby";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from "@material-ui/core/Modal/Modal";
import ReactHtmlParser, {processNodes} from "react-html-parser";
import Link from "../Link";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export default function GlobalAlert() {

  const classes = useStyles();

    const [open, setOpen] = React.useState(true);

  const data = useStaticQuery(graphql`
      query globalAlert {
        wp {
          alertBox {
            AlertBox {
              fieldGroupName
              showAlert
              alertContent
            }
          }
        }
      }
    `);

    const isBrowser = typeof window!==`undefined`;

    const hasSeen = () => {
        if(isBrowser){
            return window.sessionStorage.getItem('hasSeenGlobalAlert');
        } else {
            return true;
        }
    }

    const handleClose = () => {
        setOpen(false);

        if(isBrowser) {
            window.sessionStorage.setItem('hasSeenGlobalAlert', true);
        }
    };

    const closeLink = (e, link) => {
        e.preventDefault();
        handleClose();
        navigate(link);
    }

    const modalCloseLink = (node) => {
        // Convert a tags to links that handle routing
        if(node.type ==='tag' && node.name === 'a'){
            // Replace the admin URL in the link if set
            const link = node.attribs.href;
            return <Link key={link} to={link} onClick={(e) => closeLink(e, link)}>{processNodes(node.children, modalCloseLink)}</Link>
        }
    };

    if(hasSeen() === null && data.wp.alertBox.AlertBox.showAlert === true) {
        return (
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.alertModal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className="alert-modal">
                    {ReactHtmlParser(data.wp.alertBox.AlertBox.alertContent, {transform : modalCloseLink})}
                    <button type="button" onClick={handleClose}>Close <span/></button>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
}