import { configureStore } from "@reduxjs/toolkit";
import {
  ShopSettings,
  WishlistSettings,
  CourseSearchSettings,
  SearchSettings,
  // CookieConsentSettings,
} from "./reducers";

import CompareAppSlice from "../components/Compare/app";
import CourseCalendarSlice from "../components/Calendar/app";

export const store = configureStore({
  reducer: {
    WishlistSettings,
    ShopSettings,
    CourseSearchSettings,
    SearchSettings,
    // CookieConsentSettings,
    CourseCalendarSlice,
    CompareAppSlice,
  },
});
