export default theme => ({
    footer: {
        position: 'relative',
        paddingTop: 70,
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100vw',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: -1,
            content: '""',
            display: 'block',
        },
        '& .footer-sitemap': {
            display: 'flex',
            width: 'calc(100% + 40px)',
            margin: '0 -20px',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& .nav-column': {
                flex: '0 0 100%',
                maxWidth: '100%',
                padding: '0 20px',
                '&:nth-of-type(2), &:nth-of-type(3)': {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 25%',
                        maxWidth: '25%',
                    },
                    [theme.breakpoints.up('lg')]: {
                        flex: '0 0 18.75%',
                        maxWidth: '18.75%',
                        marginRight: '6.25%'
                    },
                },
                [theme.breakpoints.up('sm')]: {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 25%',
                    maxWidth: '25%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 18.75%',
                    maxWidth: '18.75%',
                    marginRight: '6.25%'
                },
                '& .links': {
                    borderTop: '1px solid '+ theme.palette.primary.contrastText,
                    paddingTop: 36,
                    paddingBottom: 24,
                    '& a': {
                        fontFamily: theme.bodyFont,
                        fontWeight: 'bold',
                        letterSpacing: '-0.008em',
                        fontSize: 16,
                        lineHeight: 1.25,
                        marginBottom: 24,
                        display: 'block',
                        textDecoration: 'none',
                        color: theme.palette.primary.contrastText,
                        [theme.breakpoints.up('md')]: {
                            paddingRight: 20,
                            position: 'relative',
                        },
                        '&::after': {
                            content: '""',
                            borderRight: '3px solid ' + theme.palette.secondary.main,
                            borderBottom: '3px solid ' + theme.palette.secondary.main,
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            transform: 'rotate(-45deg)',
                            marginLeft: 10,
                            [theme.breakpoints.up('md')]: {
                                position: 'absolute',
                                right: 0,
                                top: '50%',
                                transform: 'translateY(-50%) rotate(-45deg)',
                            },
                        }
                    }
                },
                '&.social-links': {
                    '& .links': {
                        '& a': {
                            width: '100%',
                            maxWidth: 200,
                            paddingRight: '0 !important',
                            [theme.breakpoints.up('sm')]: {
                                maxWidth: 'none',
                            },
                            '& svg': {
                                color: theme.palette.secondary.main,
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                float: 'right',
                                marginTop: -2,
                            },
                            '&::after': {
                                display: 'none',
                            }
                        }
                    }
                }
            }
        },
        '& .footer-extras': {
            padding: '60px 0 40px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
            '&::before': {
                height: 4,
                width: '100vw',
                top: -2,
                left: '50%',
                transform: 'translateX(-50%)',
                content: '""',
                position: 'absolute',
                display: 'block',
                backgroundColor: theme.palette.primary.contrastText,
            },
            '& .site-logo': {
                textAlign: 'center',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 100%',
                    maxWidth: '100%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 auto',
                    maxWidth: 'none',
                },
                '& a': {
                    display: 'table',
                    margin: '0 auto 50px',
                    [theme.breakpoints.up('lg')]: {
                        marginBottom: 0,
                    },
                }
            },
            '& .accessibility-links': {
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: 36,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                },
                '& a': {
                    color: theme.palette.primary.contrastText,
                    textDecoration: 'underline !important',
                    fontFamily: theme.bodyFont,
                    fontSize: 16,
                    lineHeight: 1.5,
                    letterSpacing: '0.016em',
                    [theme.breakpoints.up('md')]: {
                        fontSize: 12,
                        padding: 10,
                        fontFamily: theme.subHeaderFont,
                    },
                }
            },
            '& .credits': {
                textAlign: 'center',
                [theme.breakpoints.up('md')]: {
                    textAlign: 'right',
                },
                '& span': {
                    display: 'block',
                    textAlign: 'center',
                    fontFamily: theme.subHeaderFont,
                    color: theme.palette.primary.contrastText,
                    textTransform: 'uppercase',
                    letterSpacing: '0.152em',
                    lineHeight: 2,
                    fontSize: 12,
                    [theme.breakpoints.up('md')]: {
                        textAlign: 'right',
                        display: 'inline-block',
                        marginRight: 10,
                        paddingRight: 10,
                        lineHeight: 1.25,
                        borderRight: '1px solid ' + theme.palette.primary.contrastText,
                        '&:nth-of-type(3)': {
                            borderRight: 'none',
                        }
                    },
                    '& a': {
                        color: theme.palette.primary.contrastText,
                        textDecoration: 'underline',
                    }
                }
            }
        }
    },
    footerTheme: {
        ...theme.footerTheme,
    }
});