import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Link from '../Link';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export default function Footer(props) {

  const data = useStaticQuery(graphql`
      query FooterItemz {
        allWpMenu {
          edges {
            node {
              id
              name
              slug
              menuItems {
                nodes {
                  id
                  title
                  url
                  path
                  label
                  target
                  order
                  childItems {
                    nodes {
                      id
                      title
                      url
                      path
                      label
                      target
                      order
                    }
                  }
                }
              }
            }
          }
        }
        wp {
            locationData {
                LocationData {
                    facebookHandle
                    instagramHandle
                    twitterHandle
                    linkedinUrl
                }
            }
        }
      }
    `);

  function renderMenu(menu) {
    return <div className="nav-column" key={menu.id}>
        <div className="links">
            {menu.menuItems.nodes.map(item => {
                return <Link key={item.id} to={item.url}>{item.label}</Link>
            })}
        </div>
    </div>
  }

  const menus = data.allWpMenu.edges
  .filter(edge => edge.node.slug.includes('footer-col'))
  .map(menu => menu.node)

  return (
    <div className={`footer-sitemap`}>
        {menus.map(menu => renderMenu(menu))}
        <div className="nav-column social-links">
            <div className="links">
                {data.wp.locationData.LocationData.facebookHandle !== null &&
                    <Link to={"https://www.facebook.com/" + data.wp.locationData.LocationData.facebookHandle }>Join Us On Facebook <FacebookIcon /></Link>
                }
                {data.wp.locationData.LocationData.twitterHandle !== null &&
                    <Link to={"https://www.twitter.com/" + data.wp.locationData.LocationData.twitterHandle }>Follow Us On Twitter <TwitterIcon /></Link>
                }
                {data.wp.locationData.LocationData.instagramHandle !== null &&
                    <Link to={"https://www.instgram.com/" + data.wp.locationData.LocationData.instagramHandle }>Follow Us On Instagram <InstagramIcon /></Link>
                }
                {data.wp.locationData.LocationData.linkedinUrl !== null &&
                    <Link to={data.wp.locationData.LocationData.linkedinUrl }>Connect on Linkedin <LinkedInIcon /></Link>
                }
            </div>
        </div>
    </div>
  );
}