let data = require('../../.brew-cache/data.json');

export const replaceMasks = (item) => {
    
    if(item===null) return item;

    if(typeof(item)==='undefined') return item;

    if(typeof(item)==='string') return replace(item);

    const response = {};
    for (const [key, value] of Object.entries(item)) {
        // If this is a string, replace the value
        if(typeof(value)==='string' && value !== null && typeof(value)!=='undefined'){
            response[key] = replace(value);
        } else {
            response[key] = value;
        }
    }

    return response;
};

const replace = (string) => {
    for (const [key, value] of Object.entries(data)) {
        string = replaceAll(string, `[${key}]`, value)
    }
    string = replaceAll(string, process.env.GATSBY_ADMIN_URL, '/')
    return string;
}

function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }