import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../Link';
import styles from './styles';
import Sitemap from './Sitemap';

const useStyles = makeStyles(theme => styles(theme));

export default function Footer(props) {

  const classes = useStyles();
  const data = useStaticQuery(graphql`
      query FooterItems {
        wp {
          themeOptions {
            ThemeOptions {
              footerLogo {
                sourceUrl
              }
            }
          }
        }
      }
    `);
  return (
    <div className={`footer ${classes.footer}  ${classes.footerTheme}`}>
      <Sitemap />
      <div className="footer-extras">
        <div className="site-logo">
            <Link to="/">
                <img src={data.wp.themeOptions.ThemeOptions.footerLogo.sourceUrl} alt=""/>
            </Link>
        </div>
        <div className="accessibility-links">
          <Link to="/privacy-notice">Privacy Notice</Link>
          <Link to="/terms-conditions">Terms & Conditions</Link>
        </div>
        <div className="credits">
          <span>&copy; 2021 Alliance Automotive Group</span>
          <span>All Rights Reserved</span>
          <span>Powered by <Link to="https://www.wearebrew.co.uk">Brew</Link></span>
        </div>
      </div>
    </div>
  );
}
