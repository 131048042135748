/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import TopNav from "./TopNav";
import Meta from "./Meta";
import "./layout.scss";
import Footer from "./Footer";
import GlobalAlert from "./GlobalAlert";
import LoginSession from "./LoginSession";

import { store } from "../app/store";

const Layout = (props) => {
  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <style>
          {
            "html,body{max-width:100%;overflow-x:clip;}body{position:relative}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"
          }
        </style>
        <Meta meta={props.meta} title={props.title} path={props.path} />
        <TopNav path={props.path} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1240,
            padding: `0 1.0875rem`,
          }}
        >
          <main>{props.children}</main>
          <Footer />
          <LoginSession />
        </div>
        <GlobalAlert />
      </Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Layout;
